.ce-credit-history {
    table, th, td {
        border-width: 1px !important;
        border-style: solid !important;
        --tw-border-opacity: 1 !important;
        border-color: rgb(206 207 207 / var(--tw-border-opacity)) !important;
        border-collapse: collapse !important;
    }

    th {
        @apply text-center text-sm font-semibold;
    }
    td {
        @apply text-sm text-gray-500;
    }
}
