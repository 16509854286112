.lpl-page {
    font-size: 14px;
    color : #333;
}
.lpl-page .carousel {
    text-align: left;
}

.lpl-page img{
    max-width:100%;
}

.lpl-page h3 {
    font-size: 22px;
    color: #80807c;
    font-weight: 500;
    margin: 10px 0;
}
.lpl-page h4 {
    color: #d4803b;
    font-size: 18px;
    font-weight: 500;
    margin: 0;
}
.lpl-page strong {
    font-weight: bold;
}

.lpl-page a {
    color: #d4803b;
}
.lpl-page .btn-bg-cst {
    background-color: #d4803b;
    border-color: #a45e24;
    color: #fff;
    padding: 6px 12px;
    border-radius: 6px;
    border: 1px solid;
    display: inline-block;
    margin-bottom: 10px;
}
.lpl-page figure {
    margin:0px;
}

.lpl-page .header {
    padding: 20px;
    color: #d4803b;
    text-align: left;
    background-color: #fff;
}
.lpl-page .nav {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.lpl-page .nav:before , .lpl-page .header:before {
    display: table;
    content: " ";
}

.lpl-page .nav-pills > li {
    float: left;
}

.lpl-page .nav > li {
    position: relative;
    display: block;
}

.lpl-page .header > .nav-pills > .active > a, .lpl-page .header > .nav-pills > .active > a:hover, .lpl-page .header > .nav-pills > li > a:hover {
    color: #000 !important;
    text-decoration: none;
    background-color: transparent !important;
    border-bottom-color: #d4803b;
    border-bottom-style: solid;
    border-bottom-width: 4px;
    border-radius: 0;
}

.lpl-page .nav > li > a {
    position: relative;
    display: block;
    padding: 10px 15px;
}

.lpl-page .nav-pills > .active > .lefttrianglehead {
    display: inline;
    border-width: 0 40px 40px 0;
}

.lpl-page .lefttrianglehead {
    position: absolute;
    right: 50%;
    z-index: 30;
    width: 0;
    height: 0;
    line-height: 0;
    border-color: transparent #fff transparent transparent;
    border-style: solid;
}

.lpl-page .nav-pills > .active > .righttrianglehead {
    display: inline;
    border-width: 40px 40px 0 0;
}

.lpl-page .righttrianglehead {
    position: absolute;
    left: 50%;
    z-index: 30;
    width: 0;
    height: 0;
    line-height: 0;
    border-color: #fff transparent transparent transparent;
    border-style: solid;
}

.lpl-page .nav-pills > li + li {
    margin-left: 2px;
}

.lpl-page .header > .nav-pills > li > a {
    border-radius: 0;
}

.lpl-page ul.nav-pills > li > .lefttrianglehead {
    display: none;
}

.lpl-page ul.nav-pills > li > .righttrianglehead {
    display: none;
}

@media only screen and (max-width: 400px)  {
    .lpl-page .nav > li > a {
        padding: 6px;
    }
    .mr-6 {
        margin-right: 0;
    }
}

.marketing-nav {
    .nav-area {
        display: flex;
        margin: 0 auto;
        padding: 10px 20px;
        width: 100%;
    }

    .menus {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        list-style: none;
      }
      
    .menu-items {
        position: relative;
    }
    
    .menu-items a {
        display: block;
        font-size: inherit;
        font-family: 'Public Sans', sans-serif;
        color: inherit;
        text-decoration: none;
    }
    
    .menu-items button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: inherit;
        font-size: inherit;
        font-family: 'Public Sans', sans-serif;
        border: none;
        background-color: transparent;
        cursor: pointer;
        width: 100%;
    }
    
    .menu-items > a, .menu-items button {
        text-align: left;
        padding: 0.7rem 1rem;
    }
    
    .menu-items a:hover,
    .menu-items button:hover {
        background-color: #f2f2f2;
        text-decoration: underline;
        text-underline-offset: 0.3rem;
    }
    
    .dropdown {
        position: absolute;
        left: 0;
        left: auto;
        box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
            0 4px 6px -2px rgba(71, 63, 79, 0.16);
        z-index: 9999;
        min-width: 15rem;
        padding: 0.5rem 0;
        list-style: none;
        background-color: #fff;
        border-radius: 0.5rem;
        display: none;
    }
    
    .dropdown.show {
        display: block;
    }
    
    .dropdown .dropdown-submenu {
        position: absolute;
        top: -7px;
    }
    
    .pull-left {
        right: 100%;
    }
    .pull-right {
        left: 100%;
    }
}
