@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Public+Sans:wght@400&display=swap');

/* Custom Components */
@import '../components/Users/ce';
@import '../pages';
@import '../pages/events/annual-conference/dashboard';
@import '../pages/events/annual-conference/registration';
@import '../pages/events/annual-conference/ticket';
@import '../pages/login/login';
@import '../pages/members/account/transaction-history';
@import '../pages/members/annual-conference/dashboard';
@import '../pages/members/annual-conference/schedule/agenda';
@import '../pages/members/community/discussion-groups';
@import '../pages/members/leader-tools/partner-hub';
@import '../pages/members/resources/courses';
@import '../components/Users/certification';

input[type='text']:focus {
    box-shadow: none;
}

body {
    @apply text-black-400;
    font-family: 'Public Sans', sans-serif;
}

a {
    text-decoration: inherit; // tailwind base property
}

.layout {
    @apply mx-auto px-3 sm:px-4 md:px-5;
    display: flex;
    flex-direction: column;
}

.sub-title {
    @apply text-3xl;
}

.table-backdrop {
    position: absolute;
    opacity: 0.2 !important;
}

.col-span-2 {
    grid-column: span 2 / span 2;
}

footer {
    background-color: #f8f8f8;
    @apply bg-disabled-50;
}
