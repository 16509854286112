.course-sessions {
    table, th, td {
        border-width: 1px !important;
        border-style: solid !important;
        --tw-border-opacity: 1 !important;
        border-color: rgb(206 207 207 / var(--tw-border-opacity)) !important;
        border-collapse: collapse !important;
    }
    th {
        @apply px-4 py-3.5 text-left text-sm font-semibold;
    }
    td {
        @apply whitespace-nowrap p-2 text-sm text-gray-500;
    }
}