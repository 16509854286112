.layout {
    height: 100%;
}

.discussion-wrapper {
    @apply my-10;

    .discussion-content {
        padding: 1rem 1.25rem;
    }

    .discussion-title {
        @apply text-secondary-500 text-lg flex justify-between mt-1;
    }

    .discussion-time,
    .discussion-location,
    .discussion-seat {
        @apply flex items-center space-x-2;

        .MuiSvgIcon-root {
            color: #C3C3C3;
        }
    }

    .discussion-content-tag {
        padding: 0.25rem 0.5rem;
        border: 1px solid #ced4da;
        border-radius: 8px;
    }

    .discussion-location--map {
        width: 60%;
        height: auto;
    }

    .discussion-thread {
        @apply my-5 space-y-5 overflow-x-auto;

        .discussion-comment {
            min-width: 600px;

            &--action {
                @apply flex flex-wrap mt-1;

                &-item {
                    @apply flex space-x-1 cursor-pointer pr-2;
                }
            }
        }
    }
}

.chat-wrapper {
    @apply shadow-xl mb-5;
    display: flex;
    flex-direction: column;
    height: 100%;

    .chat-messages-wrapper {
        flex: 1;
        position: relative;

        .chat-messages {
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            overflow-y: auto;
            position: absolute;
            display: flex;
            flex-direction: column;
            @apply py-3 px-5;

            .msg {
                border: 1px solid #ddd;
                padding: 7px 15px;
                border-radius: 20px;
                font-size: 13px;
                margin-bottom: 20px;
                max-width: 80%;
                width: fit-content;
                width: -moz-fit-content;
                align-self: end;
                // float: left;
            }

            .msg-dark {
                @apply bg-gray-50;
                // float: right;
                border: 1px solid #ddd;
                padding: 7px 15px;
                border-radius: 20px;
                font-size: 13px;
                margin-bottom: 20px;
                max-width: 80%;
                width: fit-content;
                width: -moz-fit-content;
                align-self: start;
            }
        }
    }

    .chat-input {
        display: flex;
        // border-top: 1px solid #ddd;
    }
}
