#ckaFirmDescription .ql-toolbar.ql-snow {
    border-color: #213C72;
    border-width: 2px;
}
#ckaFirmDescription .ql-container.ql-snow {
    border-color: #213C72;
    border-width: 2px;
}
#ckaFirmDescription .ql-container .ql-editor {
    font-family: 'Public Sans';
    font: inherit;
    font-size: 16px;
    font-weight: 500;
}
.certification-apply {
    table {
        @apply border-collapse;
        min-width: 500px;
        tbody {
            tr:nth-child(odd) {
                @apply bg-gray-50;
            }
        }
        th {
            @apply py-3
        }
        tr {
            @apply border-t-0 border-l-0 border-r-0 border-b border-solid border-gray-100;
        }
        td {
            @apply py-3 px-3;
        }
    }
}