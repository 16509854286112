.transaction-invoice {
    table {
        border-spacing: 0;
        border: none
    }
    th, td, {
        border-width: 1px;
        border-style: solid;
        --tw-border-opacity: 1;
        border-color: rgb(206 207 207 / var(--tw-border-opacity));
        border-collapse: collapse;
    }
    th {
        @apply px-4 py-3.5 text-left text-sm font-semibold;
    }
    td {
        @apply whitespace-nowrap p-4 text-sm text-gray-500;
    }
}

@media print {
    .print-margin-all {
        @apply m-24;
    }
    .print-margin-top {
        @apply mt-24;
    }
    table {
        border-spacing: 0;
        border: none
    }
    th, td {
        border-width: 1px;
        border-style: solid;
        --tw-border-opacity: 1;
        border-color: rgb(206 207 207 / var(--tw-border-opacity));
        border-collapse: collapse;
    }
    th {
        @apply px-4 py-3.5 text-left text-sm font-semibold;
    }
    td {
        @apply whitespace-nowrap p-4 text-sm text-gray-500;
    }
}
