.partnerhub-resources {
    table {
        @apply border-collapse;
        text-align: left;
        min-width: 500px;
        th {
            @apply py-3
        }
        tr {
            @apply border-t-0 border-l-0 border-r-0 border-b border-solid border-gray-100 hover:bg-gray-50;
        }
        tr:last-child {
            border-bottom-width: 0px;
        }
        td {
            @apply py-3 px-3;
        }
        .resource-extension {
            width: 1%;
        }
    }
}
