.registration-teal-wrapper {
    @apply flex flex-col border-2 border-solid border-secondary-500 rounded-xl;
}

.registration-red-wrapper {
    @apply flex flex-col border-2 border-solid border-red-400 rounded-xl;
}

.registration-info-frame {
    &--bg-red {
        @apply bg-red-400 text-xl text-white p-3 w-full flex rounded-t-lg;
    }
    &--bg-teal {
        @apply bg-secondary-500 text-xl text-white p-3 w-full flex justify-between rounded-t-lg;
    }
}

.registration-badge-frame {
    @apply text-white;
    &--border-red {
        @apply border-l-8 border-r-8 border-b-8 border-solid border-red-400;
    }
    &--border-teal {
        @apply border-l-8 border-r-8 border-b-8 border-solid border-blue-950;
    }
}
