@tailwind base;

@layer base {
  @font-face {
        font-family:"new-spirit";
        src:url("https://use.typekit.net/af/73dea6/00000000000000007735c4b8/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff2"),url("https://use.typekit.net/af/73dea6/00000000000000007735c4b8/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff"),url("https://use.typekit.net/af/73dea6/00000000000000007735c4b8/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("opentype");
        font-display:auto;font-style:normal;font-weight:300;font-stretch:normal;
    }
    @font-face {
      font-family:"inter-variable";
      src:url("https://use.typekit.net/af/0accda/00000000000000007750957d/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/0accda/00000000000000007750957d/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/0accda/00000000000000007750957d/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
      font-display:auto;font-style:normal;font-weight:400;font-stretch:normal;
  }
  @font-face {
    font-family: Rokkitt-Black;
    src: url("./assets/fonts/Rokkitt-Black.ttf") format("truetype");
  }
  @font-face {
    font-family: Rokkitt-Bold;
    src: url("./assets/fonts/Rokkitt-Bold.ttf") format("truetype");
  }
  @font-face {
    font-family: Rokkitt-ExtraBold;
    src: url("./assets/fonts/Rokkitt-ExtraBold.ttf") format("truetype");
  }
  @font-face {
    font-family: Rokkitt-ExtraLight;
    src: url("./assets/fonts/Rokkitt-ExtraLight.ttf") format("truetype");
  }
  @font-face {
    font-family: Rokkitt-Light;
    src: url("./assets/fonts/Rokkitt-Light.ttf") format("truetype");
  }
  @font-face {
    font-family: Rokkitt-Medium;
    src: url("./assets/fonts/Rokkitt-Medium.ttf") format("truetype");
  }
  @font-face {
    font-family: Rokkitt-Regular;
    src: url("./assets/fonts/Rokkitt-Regular.ttf") format("truetype");
  }
  @font-face {
    font-family: Rokkitt-SemiBold;
    src: url("./assets/fonts/Rokkitt-SemiBold.ttf") format("truetype");
  }
  @font-face {
    font-family: Rokkitt-Thin;
    src: url("./assets/fonts/Rokkitt-Thin.ttf") format("truetype");
  }
  @font-face {
    font-family: Montserrat-Light;
    src: url("./assets/fonts/Montserrat-Light.ttf") format("truetype");
  }
  @font-face {
      font-family: Montserrat-Regular;
      src: url("./assets/fonts/Montserrat-Regular.ttf") format("truetype");
    }
    @font-face {
      font-family: Montserrat;
      src: url("./assets/fonts/Montserrat-Regular.ttf") format("truetype");
    }
    @font-face {
      font-family: Montserrat-Bold;
      src: url("./assets/fonts/Montserrat-Bold.ttf") format("truetype");
    }
    @font-face {
      font-family: Montserrat-BoldItalic;
      src: url("./assets/fonts/Montserrat-BoldItalic.ttf") format("truetype");
    }
    @font-face {
      font-family: Montserrat-Italic;
      src: url("./assets/fonts/Montserrat-Italic.ttf") format("truetype");
    }
    @font-face {
      font-family: Montserrat-Black;
      src: url("./assets/fonts/Montserrat-Black.ttf") format("truetype");
    }
    @font-face {
      font-family: Montserrat-ExtraBold;
      src: url("./assets/fonts/Montserrat-ExtraBold.ttf") format("truetype");
    }
    @font-face {
      font-family: Montserrat-SemiBold;
      src: url("./assets/fonts/Montserrat-SemiBold.ttf") format("truetype");
    }
  @font-face {
      font-family: Montserrat-Bold;
      src: url("./assets/fonts/Montserrat-Bold.ttf") format("truetype");
    }

}

