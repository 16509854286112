.registration-orange-wrapper {
    @apply flex border-2 border-solid border-orange-400 rounded-xl;
    &--add-ticket {
        input[type=number]::-webkit-inner-spin-button, 
        input[type=number]::-webkit-outer-spin-button { 
            -webkit-appearance: none; 
            margin: 0; 
        }
    }
    .donation-amount {
        input[type=number]::-webkit-inner-spin-button, 
        input[type=number]::-webkit-outer-spin-button { 
            -webkit-appearance: none; 
            margin: 0; 
        }
    }
}

.registration-red-wrapper {
    @apply flex border-2 border-solid border-red-400 rounded-xl;
}

.registration-teal-wrapper {
    @apply flex border-2 border-solid border-secondary-500 rounded-xl;
    .donation-amount {
        input[type=number]::-webkit-inner-spin-button, 
        input[type=number]::-webkit-outer-spin-button { 
            -webkit-appearance: none; 
            margin: 0; 
        }
    }
}

.ticket--ka-id {
    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button { 
        -webkit-appearance: none; 
        margin: 0; 
    }
}
