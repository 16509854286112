.layout {
    height: 100%;
}

.agenda-wrapper {
    border: 1px solid #ced4da;
    color: #696969;
    @apply m-5;
    .agenda-content {
        padding: 1rem 1.25rem;
    }
    .agenda-title {
        @apply text-secondary-500 text-lg flex justify-between mt-1;
    }
    .agenda-time, .agenda-location, .agenda-seat {
        @apply flex items-center space-x-2;
        .MuiSvgIcon-root {
            color: #C3C3C3;
        }
    }
    .agenda-content-tag {
        width: fit-content;
        padding: 0.05rem 0.5rem;
        border: 1px solid #ced4da;
        border-radius: 8px;
        font-size: 0.8rem;
    }
    .agenda-location--map {
        width: 60%;
        height: auto;
    }
    .agenda-discussion-thread {
        @apply my-5 space-y-5 overflow-x-auto;
        .agenda-comment {
            min-width: 600px;
            &--action {
                @apply flex flex-wrap mt-1;
                &-item {
                    @apply flex space-x-1 cursor-pointer pr-2;
                }
            }
        }
    }
}

.agenda-wrapper--detail {
    border: 1px solid #ced4da;
    @apply m-5;
    .agenda-content--detail {
        padding: 1.25rem;
    }
    .agenda-title--detail {
        @apply text-secondary-500 text-lg flex justify-between;
    }
    .agenda-time--detail, .agenda-location--detail, .agenda-seat--detail {
        @apply flex items-center space-x-2;
        .MuiSvgIcon-root {
            color: #C3C3C3;
        }
    }
}
